/* MUI Table Chip color */
.tss-1o8fckl-MUIDataTableFilterList-chip {
  margin: 8px 8px 8px 0px !important;
  color: #ffffff !important;
}

.css-6d5ttf-MuiButtonBase-root-MuiChip-root .MuiChip-deleteIcon {
  -webkit-tap-highlight-color: transparent;
  color: rgba(255, 255, 255, 0.26) !important;
  font-size: 22px;
  cursor: pointer;
  margin: 0 5px 0 -6px;
}
.post {
  scroll-behavior: smooth;
  /* display: none; */
}
.YourHighlightClass {
  font-size: 14px;
}

/* .MuiTableContainer-root {
  height: 55vh !important;
} */
em {
  background-color: yellow;
}

@page {
  size: A4 portrait;
}

@page: blank {
  @top-left-corner {
    content: none;
  }
  @top-left {
    content: none;
  }
  @top-center {
    content: none;
  }
  @top-right {
    content: none;
  }
  @top-right-corner {
    content: none;
  }
  @right-top {
    content: none;
  }
  @right-middle {
    content: none;
  }
  @right-bottom {
    content: none;
  }
  @bottom-right-corner {
    content: none;
  }
  @bottom-right {
    content: none;
  }
  @bottom-center {
    content: none;
  }
  @bottom-left {
    content: none;
  }
  @bottom-left-corner {
    content: none;
  }
  @left-bottom {
    content: none;
  }
  @left-middle {
    content: none;
  }
  @left-top {
    content: none;
  }
}

@page: left {
  margin-left: 22mm;
  margin-right: 14mm;
  margin-top: 73px;
  margin-bottom: 73px;

  @top-left {
    content: counter(page);
    font-weight: 400;
    font-family: var(--serif);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: var(--line-height);
    vertical-align: bottom;
    margin-bottom: 14px;
    font-variant-numeric: oldstyle-nums;
  }

  @top-center {
    content: string(booktitle);
    font-weight: 400;
    font-family: var(--serif);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: var(--line-height);
    vertical-align: bottom;
    margin-bottom: 14px;
    font-variant-numeric: oldstyle-nums;
    font-variant: small-caps;
    width: 85mm;
    text-align: left;
  }
}

@page: right {
  margin-left: 14mm;
  margin-right: 22mm;
  margin-top: 73px;
  margin-bottom: 73px;
  @top-right {
    content: counter(page);
    font-weight: 400;
    font-family: var(--serif);
    font-size: var(--font-size);
    font-weight: normal;
    line-height: var(--line-height);
    vertical-align: bottom;
    margin-bottom: 14px;
    font-variant-numeric: oldstyle-nums;
    text-align: right;
  }

  @top-center {
    content: element(shorter);
    vertical-align: bottom;
    text-align: right;
    margin-bottom: 1px;
    width: 85mm;
  }
}

/* .css-1hycezf-MuiPaper-root {
  background-color: #fff;
  color: #212B36;
  transition: none;
  border-radius: 8px;
  box-shadow: none !important;
  background-image: none !important; 
} */

/* .login-side-container {
  background: url("../../public/static/illustrations/login_bg.png") no-repeat scroll left center/cover;
} */

.container {
  border: 1px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.special {
  background-color: #ffec40; /* Customize the style as needed */
}

.category-container {
  border: 2px solid #ccc;
  padding: 10px;
  margin: 10px;
}

.item-container {
  border: 1px solid #ddd;
  padding: 5px;
  margin: 5px;
}

/* .line {
  flex-grow: 1;
  height: 2px;
  background-color: #000;
} */

.builder-box-container {
  display: flex;
}

.builder-box-group {
  border: 2px dashed #212668; /* Change the border color as needed */
  margin: 10px 190px 10px 0; /* Adjust spacing between groups */
  /* padding: 10px; */
}

.builder-box {
  border: 1px solid red;
  padding: 10px;
  margin: 10px;
}

.box-line-right {
  position: absolute;
  top: 39%;
  left: 100%;
  height: 2px;
  width: 57px; /* Adjust the length of the line as needed */
  background-color: #212668;
}
.box-line-left {
  position: absolute;
  top: 39%;
  right: 100%;
  height: 2px;
  width: 47px; /* Adjust the length of the line as needed */
  background-color: #212668;
}

.box-line-circle {
  position: absolute;
  top: 42%;
  left: 100%;
  height: 2px;
  width: 39px; /* Adjust the length of the line as needed */
  background-color: #212668;
}

.connect-line {
  /* position: absolute; */
  top: 39%;
  left: 97%;
  height: 4px;
  width: 202x; /* Adjust the length of the line as needed */
  background-color: #212668;
}

.scroll-container {
  overflow-x: auto;
  white-space: nowrap;
}

.grouped-box-line-right {
  position: absolute;
  top: 39%;
  left: 136%;
  height: 2px;
  width: 56px; /* Adjust the length of the line as needed */
  background-color: #212668;
}
.grouped-box-line-left {
  position: absolute;
  top: 39%;
  right: 109%;
  height: 2px;
  width: 43px; /* Adjust the length of the line as needed */
  background-color: #212668;
}
.not-grouped-box-line-left {
  position: absolute;
  top: 39%;
  right: 100%;
  height: 2px;
  width: 83px; /* Adjust the length of the line as needed */
  background-color: #212668;
}

.last-grouped-box-line-right {
  position: absolute;
  top: 25%;
  left: 136.5%;
  height: 2px;
  width: 51px; /* Adjust the length of the line as needed */
  background-color: #212668;
}

/* detailsClass
summaryOutterClass
summaryInnerClass */

/* details>summary {
 
  list-style: none;
}
summary::-webkit-details-marker {
  display: none
} */

.summaryInnerClass::before {
  content: ' ►';
}
.detailsClass[open] .summaryInnerClass:before {
  content: ' ▼';
}

/* Admin -- Developer Dashboard -- Documentation style */
.api-content {
  margin-left: 250px !important;
}
/* .menu-content {
  position: fixed !important;
  top: 260px !important;
} */
.menu-content {
  position: fixed !important;
  /* position: sticky !important; */
  top:210px !important
}
.title {
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(2rem, 5vw, 4rem);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.5em;
  font-weight: 200;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4),
    0 0 40px rgba(255, 182, 255, 0.3);
  opacity: 0.92;
  z-index: 1000;
  animation: glow 2s ease-in-out infinite alternate;
  padding-left: 0.4em;
}

.fresh-title {
  color: #fff;
  font-family: 'Montserrat', sans-serif;
  font-size: clamp(2rem, 5vw, 4rem);
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 0.4em;
  font-weight: 200;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 0 0 20px rgba(255, 255, 255, 0.6), 0 0 30px rgba(255, 255, 255, 0.4),
    0 0 40px rgba(255, 182, 255, 0.3);
  opacity: 0.92;
  z-index: 1000;
  animation: glow 2s ease-in-out infinite alternate;
}
